// Get status alert container
const statusContainer = document.querySelector('#alert--container')

// Show the error messages in a call-out
function outputMsg(img, status) {
	// Get parent and sibling elements
	const row = img.closest('tr')
	const link = row.querySelector('a')
	const para = row.querySelector('p')
	// Set vars based off elements
	const statusHref = link ? link.href : 'https://helpdesk.uvic.ca/tools/index.php?next_page=informed/view_do.php&sort=effective_desc'
	const category = link ? link.innerText : null
	let service = para ? para.innerText : category || 'one of our systems'
	// console.log({statusHref}, {category}, {service});

	// Generate message to display
	let message = null
	if (status == 'difficulties') {
		message = `We're having some technical issues with ${service}.`
	} else if (status == 'outage') {
		message = `<span style="text-transform: capitalize">${service}</span> is currently down.`
	} else if (status == 'planned') {
		message = `A planned outage for ${service} is happening now.`	
	}
	
	// Output the message
	if (message) {
		// Flush out message
		message		= `<p><a href="${statusHref}">${message}</a></p>`

		// Create alert message if it doesn't exist
		if (!statusContainer.querySelector('.alert.callout')) {
			statusContainer.innerHTML = `
			<div class="alert callout callout--icon callout--close bg--yellow text--black" role="alert">
				<i class="fa-solid fa-exclamation-triangle callout__icon"></i>
				<div class="callout__text"></div>
				<button type="button" class="btn callout__close" data-bs-dismiss="alert" aria-label="Close"><i class="far fa-times-circle" aria-hidden="true"></i></button>
			</div>`
			statusContainer.classList.add('mb-4')
		}
		const statusAlert = statusContainer.querySelector('.alert.callout .callout__text')

		statusAlert.insertAdjacentHTML('beforeend', message)
	}
}

// Fetch the status page content
const fetchStatus = async () => {
	// Set the URL for the status page
	const statusHost = window.location.hostname
	let statusUrl = ''
	if (statusHost === 'localhost' || statusHost === 'web.uvic.ca') {
		statusUrl = "status/status.php"
	} else if (statusHost === 'dev.uvic.ca' || statusHost === 'test.uvic.ca') {
		statusUrl = "/systems/assets/components/content/systems-status-test.php"
	} else if (statusHost === 'www.uvic.ca') {
		statusUrl = "/systems/assets/components/content/CasSystemsStatus.php"
	}

	// fetch the status page from the URL
	const response = await fetch(statusUrl)

	// page didn't load, bail
	if (response.status !== 200) {
		return
	}

	// Convert the HTML string into a document object
	const parser = new DOMParser()
	const statusPage = parser.parseFromString(await response.text(), 'text/html')
	// console.log(statusPage);

	// Find all the different error images, then output all the error messages
	statusPage.querySelectorAll('img[src*="/Experiencing_difficulties.gif"]').forEach(gif => outputMsg(gif, 'difficulties'))
	statusPage.querySelectorAll('img[src*="/Outage.gif"]').forEach(gif => outputMsg(gif, 'outage'))
	statusPage.querySelectorAll('img[src*="/Planned_outage.gif"]').forEach(gif => outputMsg(gif, 'planned'))
}
// Only fetch status if there's somewhere to display them
if (statusContainer) {
	fetchStatus()
}